import $ from 'jquery';
import { gsap, TimelineMax, Power2, Power4, CSSRulePlugin, ScrollTrigger } from 'gsap/all';
require("../__vendor/SplitText.min.js");

gsap.registerPlugin(CSSRulePlugin, ScrollTrigger); 

const loader = () => {
    console.log("LOADER");
};

export default loader;